import enquire from 'enquire.js'
import { ready } from '../vendor/polyfills.js'
import rwd from '../utils/rwd.js'
import { iosMultilineSelect } from '../utils/browserSpecific.js'
import handleSliderArrows from '../utils/handleSliderArrows.js'
import { getSkinUrl } from '../utils/utils'
import { getTooltipDataFromCms } from '../utils/getCmsBlocks'
import { fetchSubcategories } from '../utils/getSubcategories'

/* ============================================ *
 * Settings for whole site
 * ============================================ */
var apSettings = {
    bp: rwd.breakpoints,
    dir: {
        skinJs: getSkinUrl('js')
    },
    mq: rwd.mq
}

global.apSettings = apSettings

var staticImpressions = staticImpressions || []

/* ============================================ *
 * Functions for whole site
 * ============================================ */
;(function () {
    'use strict'

    function scrollTo(element, yOffset = -120) {
      if (element) {
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        setTimeout(() => window.scroll({top: y, behavior: 'smooth'}), 10);
      }
    }

    function Site(settings) {
        this.windowLoaded = false

        // declare breakpoints
        this.bp = apSettings.bp
        this.bp.current = 'lg'

        // declare media queries
        this.mq = apSettings.mq

        // get directories
        this.dir = apSettings.dir

        // lazy load plugin
        this.blazy = null

        // get current url to api
        this.appUrl = document.location.origin
    }

    function checkIsHover(e) {
        return e.parentElement.querySelector(':hover') === e
    }

    function gliderAutoplay(glider, selector, delay) {
        var autoplayDelay = delay

        var autoplay = setInterval(function () {
            glider.scrollItem('next')
        }, autoplayDelay)

        selector.addEventListener(
            'mouseover',
            function (event) {
                if (autoplay != null) {
                    clearInterval(autoplay)
                    autoplay = null
                }
            },
            300
        )

        selector.addEventListener(
            'mouseout',
            function (event) {
                if (autoplay == null) {
                    autoplay = setInterval(function () {
                        glider.scrollItem('next')
                    }, autoplayDelay)
                }
            },
            300
        )
    }

    Site.prototype = {
        constructor: Site,

        start: function () {
            var me = this

            window.addEventListener('load', function () {
                me.windowLoaded = true
            })

            this.attach()
        },

        /* ============================================ *
         * Attach main functions on ready
         * ============================================ */
        attach: function () {
            this.attachPlugins()
            this.attachResponsive()
            this.headerSticky()
            this.slidersInit()
            typeof __CONTEXT__ === 'undefined' && this.filterNavigation()
            this.goToMainContent()
            this.megaMenuOld()
            this.pager()
            this.faq()
            this.aoeStaticCallback()
            this.initSliders()
            this.initAgreements()
            this.initHeader()
            this.initHeaderOverlay()
            this.scrollBreadcrumbs()
            this.initDesktopBreadcrumbs()
            this.sliceExpandable()
            this.showElementsOnReady()
            this.inputTrimmer()
            this.initIntlTelInput()
            this.hellobar()
            this.fixedCartBox()
            this.scrollToAnchor()
            this.rolloutFooterMegaLinks()
            this.showFooterArticle()
            this.tabs()
            this.cartStickySummaryTop()

            // Mega Menu
            import(
                /* webpackChunkName: "megaMenu" */ './megaMenu.js'
            ).then(({ megaMenu }) => megaMenu())

            // FOOTER
            import(
                /* webpackChunkName: "newsletterFooter" */ './newsletterFooter.js'
            ).then(({ newsletterFooter }) => newsletterFooter())

            // SUGGESTER

            import(/* webpackChunkName: "suggester" */ './suggester.js').then((SuggesterModule) => {
                const SuggesterMain = SuggesterModule.default;
                const SuggesterSticky = SuggesterModule.default;

                const suggester_main = new SuggesterMain('.js-main-suggester');
                const suggester_sticky = new SuggesterSticky('.js-sticky-suggester');
            });
        },

        /* ============================================ *
         * Show elements when ready
         * Usage: global
         * ============================================ */
        showElementsOnReady: function () {
            var showOnReadyElements = document.querySelectorAll(
                '.show-on-ready'
            )
            Array.from(showOnReadyElements).forEach(function (el, i) {
                el.style.display = 'block'
            })
        },

        /* ============================================ *
         * Expandable element
         * Usage: global
         * ============================================ */
        sliceExpandable: function () {
            var expandable = document.querySelectorAll('.expandable--400')

            Array.from(expandable).forEach(function (el, i) {
                var fullText = el.innerHTML
                var shortText = fullText
                    .slice(0, 400)
                    .split(' ')
                    .slice(0, -1)
                    .join(' ')

                el.style.display = 'block'

                if (fullText.length > 400) {
                    var togglerExpand = createToggleElement(
                        'expand--400',
                        ' ...czytaj więcej'
                    )
                    el.innerHTML = shortText
                    el.appendChild(togglerExpand)

                    togglerExpand.addEventListener('click', function (e) {
                        var togglerCollapseWrap = document.createElement('div')
                        var togglerCollapse = createToggleElement(
                            'collapse--400',
                            'zwiń'
                        )

                        el.innerHTML = fullText
                        el.appendChild(togglerCollapseWrap)
                        togglerCollapseWrap.style.textAlign = 'right'
                        togglerCollapseWrap.appendChild(togglerCollapse)

                        togglerCollapse.addEventListener('click', function (e) {
                            el.innerHTML = shortText
                            el.appendChild(togglerExpand)
                            var elPosition =
                                el.getBoundingClientRect().top +
                                window.scrollY -
                                35
                            window.scrollTo(0, elPosition)
                            e.preventDefault()
                        })

                        e.preventDefault()
                    })
                }
            })

            function createToggleElement(elClass, elText) {
                var toggleElement = document.createElement('a')
                toggleElement.setAttribute('href', '#')
                toggleElement.classList.add(elClass)
                toggleElement.textContent = elText
                return toggleElement
            }
        },

        /* ============================================ *
         * If there is more than 6 levels, 5th and 6th levels are replaced by (...) that is linking to previous level
         * Usage: global
         * ============================================ */
        initDesktopBreadcrumbs: function () {
            var blackList = ['checkout-onepage-angular'] // don't initialize breadcrumbs on those subpages
            var isOnBlackList = false
            var breadcrumbs = document.getElementsByClassName('breadcrumbs')[0]
            if (breadcrumbs !== undefined) {
                var numChildren = breadcrumbs.children.length
                if (window.innerWidth >= 1200) {
                    blackList.forEach(function (ignoredClass) {
                        if (document.body.classList.contains(ignoredClass)) {
                            isOnBlackList = true
                            return false
                        }
                    })
                    if (isOnBlackList) {
                        return false
                    }
                    breadcrumbs.style.display = 'block'
                    if (numChildren > 5) {
                        breadcrumbs.querySelector(
                            'li:nth-child(5)'
                        ).style.display = 'none'
                        breadcrumbs.querySelector(
                            'li:last-child'
                        ).style.display = 'inline'
                        var ellipsisCrumb = breadcrumbs.querySelector(
                            'li:nth-child(' + (numChildren - 1) + ')'
                        )
                        ellipsisCrumb.style.display = 'inline'
                        ellipsisCrumb.getElementsByTagName('a')[0].textContent =
                            '...'
                    }
                }
            }
        },

        /* ============================================ *
         * Scroll breadcrumbs to the end on mobile
         * Usage: global
         * ============================================ */
        scrollBreadcrumbs: function () {
            var breadcrumbs = document.querySelector('.breadcrumbs')
            if (breadcrumbs !== null && window.innerWidth < 1200) {
                breadcrumbs.scrollTo(breadcrumbs.scrollWidth, 0)
            }
        },

        /* ============================================ *
         * Mobile navigation
         * Usage: global
         * ============================================ */
        initHeaderOverlay: function () {
            // init header overlay only on mobile and tablet
            var mobileMenu = document.getElementById('menu-mobile-root')

            if (mobileMenu !== null) {
                var notCategoriesLi = document.querySelectorAll(
                    '.nav-main__primary li:not(.nav-categories)'
                )

                Array.from(notCategoriesLi).forEach(function (el) {
                    el.addEventListener('click', function (e) {
                        e.stopPropagation()
                    })
                })

                ready(function () {
                    var navClosingElements = document.querySelectorAll(
                        '.header-overlay .icon-close, #header-overlay-backdrop'
                    )
                    Array.from(navClosingElements).forEach(function (el) {
                        el.addEventListener('click', function (e) {
                            closeMobileMenu()
                            e.preventDefault()
                        })
                    })
                })

                var navOpeningElements = document.querySelectorAll(
                    '.js-open-menu'
                )

                Array.from(navOpeningElements).forEach(function (el) {
                    el.addEventListener('click', function (e) {
                        document.querySelector(
                            '.header-overlay'
                        ).style.display = 'block'
                        document.body.classList.add('is-mobile-menu-open')

                        /* Detect active menu items and open correct submenus */

                        var mobileMenuItems = mobileMenu.querySelectorAll('li')
                        var mobileMenuHasActiveItems = false

                        Array.from(mobileMenuItems).forEach(function (el) {
                            if (el.classList.contains('active')) {
                                mobileMenuHasActiveItems = true
                                return
                            }
                        })

                        if (mobileMenuHasActiveItems) {
                            mobileMenu.classList.add('is-mobile-submenu-open')

                            var mobileMenuActiveItems = mobileMenu.querySelectorAll(
                                'li.active'
                            )

                            Array.from(mobileMenuActiveItems).forEach(function (
                                el,
                                i
                            ) {
                                el.parentElement.classList.add('is-opened')

                                /* Highlight only last active element */
                                if (i == mobileMenuActiveItems.length - 1) {
                                    el.classList.add('current')
                                }
                            })
                        }

                        e.preventDefault()
                    })
                })

                /* Get all menu links and look for submenus */
                var mobileMenuItems = mobileMenu.querySelectorAll('li')

                function addMenuListener(el) {
                    el.addEventListener(
                        'click',
                        (function () {
                            var alreadyOpened = false

                            return async function (ev) {
                                var target = ev.target,
                                    parentLI =
                                        target.parentElement.parentElement,
                                    parentUL = parentLI.parentElement,
                                    parentULList = parentUL.querySelectorAll(
                                        'li'
                                    )

                                if (!target.classList.contains('arrow')) {
                                    return
                                }
                                ev.preventDefault()
                                ev.stopPropagation()

                                if (
                                    parentLI.classList.contains('was-clicked')
                                ) {
                                    parentLI.classList.remove('was-clicked')
                                    return
                                }

                                if (alreadyOpened) {
                                    changeActiveMenuItem(parentULList, parentLI)
                                } else {
                                    alreadyOpened = true

                                    const subcategories = await fetchSubcategories(
                                        target.dataset.id
                                    )
                                    changeActiveMenuItem(parentULList, parentLI)

                                    if (subcategories.length > 0) {
                                        parentLI.appendChild(
                                            createSubmenu(subcategories)
                                        )
                                    }
                                }
                            }
                        })()
                    )
                }

                function changeActiveMenuItem(parentULList, parentLI) {
                    parentULList.forEach(function (el) {
                        el.classList.remove('was-clicked')
                    })
                    parentLI.classList.add('was-clicked')
                }

                Array.from(mobileMenuItems).forEach(function (el) {
                    el.classList.add('menu-link')
                    addMenuListener(el)
                })

                /* Close menu with gesture (touch events) */

                if (checkTouchEnabled()) {
                    menuGesture()
                }
                function createSubmenu(subcategories) {
                    const submenu = document.createElement('ul')
                    submenu.classList.add('mobile-submenu')
                    subcategories.forEach((subcategory) => {
                        const li = document.createElement('li')
                        li.classList.add('menu-link')
                        const a = document.createElement('a')
                        if (subcategory.hasChildren) {
                            a.classList.add('has-submenu')
                            a.innerHTML = `${subcategory.name}<button data-id="${subcategory.id}" class="arrow"></button>`
                        } else {
                            a.innerText = subcategory.name
                        }
                        a.classList.add('mobile-submenu__link')
                        a.setAttribute('href', subcategory.link)
                        addMenuListener(li)
                        li.appendChild(a)
                        submenu.appendChild(li)
                    })
                    return submenu
                }
            }

            function checkTouchEnabled() {
                var touchEnabled = window.TouchEvent
                touchEnabled = touchEnabled === undefined ? false : true
                return touchEnabled
            }

            function menuGesture() {
                var pageWidth = window.innerWidth || document.body.clientWidth
                var threshold = Math.max(1, Math.floor(0.01 * pageWidth))
                var touchstartX = 0
                var touchstartY = 0
                var touchendX = 0
                var touchendY = 0

                var limit = Math.tan(((45 * 1.5) / 180) * Math.PI)
                var menuGestureZone = document.getElementsByClassName(
                    'header-overlay'
                )[0]

                menuGestureZone.addEventListener(
                    'touchstart',
                    function (event) {
                        touchstartX = event.changedTouches[0].screenX
                        touchstartY = event.changedTouches[0].screenY
                    },
                    false
                )

                menuGestureZone.addEventListener(
                    'touchend',
                    function (event) {
                        touchendX = event.changedTouches[0].screenX
                        touchendY = event.changedTouches[0].screenY
                        var x = touchendX - touchstartX
                        var y = touchendY - touchstartY
                        var yx = Math.abs(y / x)
                        if (
                            Math.abs(x) > threshold ||
                            Math.abs(y) > threshold
                        ) {
                            if (yx <= limit) {
                                if (x < 0) {
                                    closeMobileMenu()
                                }
                            }
                        }
                    },
                    false
                )
            }

            function closeMobileMenu() {
                var headerOverlay = document.getElementsByClassName(
                    'header-overlay'
                )[0]

                document.body.classList.add('is-mobile-menu-closing')

                headerOverlay.addEventListener(
                    'animationend',
                    function headerOverlayClose() {
                        document.body.classList.remove('is-mobile-menu-open')
                        document.body.classList.remove('is-mobile-menu-closing')
                        headerOverlay.style.display = 'none'
                        headerOverlay.removeEventListener(
                            'animationend',
                            headerOverlayClose
                        )
                    }
                )
            }
        },

        /* ============================================ *
         * Header dynamic elements
         * Usage: global
         * ============================================ */
        initHeader: function () {
            document.documentElement.addEventListener(
                'aoestatic_afterblockreplace',
                () => {
                    let isDropdownOpened = false
                    const aoestatic_w1_isloggedin = Cookies.get(
                        'aoestatic_w1_isloggedin'
                    )
                    const loginCookieEls = Array.from(
                        document.querySelectorAll('.login-cookie')
                    )
                    loginCookieEls.forEach((loginCookieEl) => {
                        const loginCookieLabelEl = loginCookieEl.querySelector(
                            '.label'
                        )
                        const accountDropdownEl = document.querySelector(
                            '.account-dropdown'
                        )

                        if (aoestatic_w1_isloggedin === '1') {
                            loginCookieEl.classList.add(
                                'account-dropdown-button'
                            )
                            loginCookieEl.setAttribute('href', '#')
                            loginCookieLabelEl.innerText = 'Moje konto'

                            accountDropdownEl.parentNode.removeChild(
                                accountDropdownEl
                            )
                            loginCookieEl.appendChild(accountDropdownEl)

                            document.documentElement.addEventListener(
                                'click',
                                () => {
                                    accountDropdownEl.style.display = 'none'
                                    isDropdownOpened = false
                                }
                            )

                            const accountLabel =  loginCookieEl.querySelector('.js-login-cookie')
                            accountLabel.addEventListener('click', (event) => {
                                if (
                                    event.target.classList.contains(
                                        'login-cookie'
                                    )
                                ) {
                                    event.preventDefault()
                                }

                                const sticky = document
                                    .querySelector('.header-sticky')
                                    .classList.contains('is-sticky')
                                if (sticky) {
                                    event.preventDefault()
                                }
                                event.stopPropagation()
                                accountDropdownEl.parentNode.removeChild(
                                    accountDropdownEl
                                )
                                loginCookieEl.appendChild(accountDropdownEl)

                                accountDropdownEl.style.display = isDropdownOpened
                                    ? 'none'
                                    : 'block'
                                isDropdownOpened = !isDropdownOpened
                            })
                        }
                    })
                }
            )

            const suggesterToggle = [...document.querySelectorAll('.js-suggester-toggle')]

            if (suggesterToggle.length > 0) {
                suggesterToggle.forEach((el) => {
                    el.addEventListener('click', () => {
                        const text = el.querySelector('span')
                        const stickySuggester = document.getElementById('header-sticky-suggester')
                        el.parentElement.classList.toggle('is-hidden')
                        if (el.parentElement.classList.contains('is-hidden')) {
                            text.textContent = 'Rozwiń'
                            stickySuggester.style.height = '20px'
                        }
                        else {
                            text.textContent = 'Ukryj'
                            stickySuggester.style.height = '60px'
                        }
                    })
                })
            }
        },

        /* ============================================ *
         * Form agreements
         * Usage: global
         * ============================================ */
        initAgreements: function () {
            var agreements = document.querySelectorAll('.agreement-expandable')
            Array.from(agreements).forEach(function (el) {
                var self = el
                var agreementExpandText = self.querySelector(
                    '.agreement-expand-text'
                )
                var agreementDescription = self.querySelector(
                    '.agreement-description'
                )
                agreementDescription.style.display = 'none'
                agreementExpandText.onclick = function () {
                    self.classList.toggle('expanded')
                    if (self.classList.contains('expanded')) {
                        agreementExpandText.innerHTML = 'zwiń<'
                        agreementDescription.style.display = 'block'
                    } else {
                        agreementExpandText.innerHTML = 'rozwiń>'
                        agreementDescription.style.display = 'none'
                    }
                }
            })
        },

        /* ============================================ *
         * Sliders
         * Usage: global
         * ============================================ */
        initSliders: function () {
            var self = this
            var productList = document.querySelector('#productList')
            var productsListSidebar = document.querySelector(
                '#productsListSidebar'
            )

            if (productList && !productsListSidebar) {
                var productUrl = productList.dataset.link
                var httpRequest = new XMLHttpRequest()
                httpRequest.addEventListener('load', function () {
                    var data = JSON.parse(this.responseText)
                    if (data.productsHtml) {
                        productList.innerHTML = data.productsHtml
                        window.Aoe_Static.replaceAjaxBlocks()

                        const TOOLTIP_PRODUCTS_CMS = Array.from(
                            productList.querySelectorAll('[data-tooltip-cms]')
                        )

                        if (TOOLTIP_PRODUCTS_CMS.length) {
                            getTooltipDataFromCms(TOOLTIP_PRODUCTS_CMS)
                        }

                        if (
                            data.productsAnalyticsData &&
                            data.productsAnalyticsData.key
                        ) {
                            window.staticImpressions =
                                data.productsAnalyticsData.data
                            dataLayer.push({ event: 'articleProductBoxLoaded' })
                        }
                        self._sliderProductRelated()
                    }
                })
                httpRequest.open('GET', productUrl)
                httpRequest.send()
            }

            if (productsListSidebar) {
                var productsListSidebarUrl = productsListSidebar.dataset.link
                var httpRequestSidebar = new XMLHttpRequest()
                httpRequestSidebar.addEventListener('load', function () {
                    var data = JSON.parse(this.responseText)
                    var sliderInitializationRequired = false
                    if (data.productsRightHtml) {
                        productsListSidebar.innerHTML = data.productsRightHtml
                        if (data.data && data.key) {
                            window.staticImpressions =
                                data.productsAnalyticsData.data
                            dataLayer.push({ event: 'articleProductBoxLoaded' })
                        }
                    }
                    if (productList && data.productsHtml) {
                        productList.innerHTML = data.productsHtml
                        if (data.data && data.key) {
                            window.staticImpressions =
                                data.productsAnalyticsData.data
                            dataLayer.push({ event: 'articleProductBoxLoaded' })
                        }
                        sliderInitializationRequired = true
                    }
                    if (sliderInitializationRequired) {
                        self._sliderProductRelated()
                    }
                })
                httpRequestSidebar.open('GET', productsListSidebarUrl)
                httpRequestSidebar.send()
            }
        },

        /* ============================================ *
         * Attach plugins for whole page
         * Usage: global
         * ============================================ */
        attachPlugins: function () {
            /* lazy load plugin init */
            this.blazy = new Blazy({
                offset: 300,
                breakpoints: [
                    {
                        width: 767,
                        src: 'data-src-xs'
                    }
                ]
            })
        },

        /* ============================================ *
         * Responsive manager
         * Usage: global
         * ============================================ */
        attachResponsive: function () {
            var vm = this

            enquire.register(this.mq.toSm, {
                match: function () {
                    vm.bp.version = 'mobile'

                    typeof __CONTEXT__ === 'undefined' &&
                        vm._rwdCategoryPage.init()
                },
                unmatch: function () {
                    typeof __CONTEXT__ === 'undefined' &&
                        vm._rwdCategoryPage.destroy()
                }
            })

            enquire.register(this.mq.sm, {
                match: function () {
                    vm.bp.current = 'sm'
                }
            })

            enquire.register(this.mq.xs, {
                match: function () {
                    vm.bp.current = 'xs'

                    setTimeout(function () {
                        vm.blazy.revalidate()
                    }, 300)
                },
                unmatch: function () {
                    setTimeout(function () {
                        vm.blazy.revalidate()
                    }, 300)
                }
            })

            enquire.register(this.mq.md, {
                match: function () {
                    vm.bp.current = 'md'
                }
            })

            enquire.register(this.mq.lg, {
                match: function () {
                    vm.bp.version = 'desktop'
                    vm.bp.current = 'lg'
                }
            })
        },

        /* ============================================ *
         * Category Page behavior in RWD
         * Usage: shop category page
         * ============================================ */
        _rwdCategoryPage: {
            filters: document.querySelector('.aside--left'),
            filtersWrapper: document.querySelector(
                '.aside--left .aside__wrapper'
            ),
            asideTitle: document.querySelector('.aside--left .aside__title'),
            asideContent: document.querySelector(
                '.aside--left .aside__content'
            ),
            categoryHeader: document.getElementsByClassName(
                'category-header'
            )[0],
            blocksTitle: document.querySelectorAll('.aside--left .block-title'),
            blocksContent: document.querySelector(
                '.aside--left .block-content'
            ),
            isCategoryView:
                document.querySelector('.catalog-category-view') != null,

            init: function () {
                /* move aside left filters to mobile container */
                var _ = this
                if (_.isCategoryView) {
                    Array.from(_.blocksTitle).forEach(function (el) {
                        el.addEventListener(
                            'click',
                            function blocksTitleHandler() {
                                var blockContent = this.parentElement.querySelector(
                                    '.block-content'
                                )
                                var blockContentParent = this.parentElement
                                var isBlockShow = blockContent.classList.contains(
                                    'in'
                                )
                                if (isBlockShow) {
                                    blockContent.setAttribute(
                                        'aria-expanded',
                                        'false'
                                    )
                                    blockContent.classList.remove('in')
                                    blockContentParent.classList.remove(
                                        'is-active'
                                    )
                                } else {
                                    blockContent.setAttribute(
                                        'aria-expanded',
                                        'true'
                                    )
                                    blockContent.classList.add('in')
                                    blockContentParent.classList.add(
                                        'is-active'
                                    )
                                }
                            }
                        )
                        el.parentElement
                            .querySelector('.block-content')
                            .classList.add('collapse')
                        el.parentElement
                            .querySelector('.block-content')
                            .setAttribute('aria-expanded', 'false')
                    })
                    _.asideTitle.addEventListener(
                        'click',
                        function asideTitleHandler() {
                            var isAsideShow = _.asideContent.classList.contains(
                                'in'
                            )
                            if (isAsideShow) {
                                this.classList.remove('is-active')
                                _.asideContent.setAttribute(
                                    'aria-expanded',
                                    'false'
                                )
                                _.asideContent.classList.remove('in')
                                _.filtersWrapper.classList.remove('is-active')
                            } else {
                                this.classList.add('is-active')
                                _.asideContent.setAttribute(
                                    'aria-expanded',
                                    'true'
                                )
                                _.asideContent.classList.add('in')
                                _.filtersWrapper.classList.add('is-active')
                            }
                        }
                    )
                    _.asideContent.setAttribute('aria-expanded', 'false')
                    _.asideContent.classList.add('collapse')
                    _.categoryHeader.parentNode.removeChild(_.categoryHeader)
                    _.filters.insertBefore(
                        this.categoryHeader,
                        this.filters.firstChild
                    )
                }
            },

            destroy: function () {
                /* revert changes */
                var _ = this
                if (_.isCategoryView) {
                    _.blocksContent.classList.remove('collapse', 'in')
                    _.blocksContent.setAttribute('aria-expanded', '')
                    _.blocksContent.style.height = ''
                    _.asideContent.classList.remove('collapse', 'in')
                    _.asideContent.setAttribute('aria-expanded', '')
                    _.asideContent.style.height = ''
                    Array.from(_.blocksTitle).forEach(function (el) {
                        el.removeEventListener('click', _.blocksTitleHandler)
                        el.parentElement.classList.remove('is-active')
                    })
                    _.asideTitle.removeEventListener(
                        'click',
                        _.asideTitleHandler
                    )
                    _.asideTitle.classList.remove('is-active')
                    _.filtersWrapper.classList.remove('is-active')
                    _.filters.nextElementSibling.insertBefore(
                        _.categoryHeader,
                        _.filters.nextElementSibling.firstChild
                    )
                }
            }
        },

        /* ============================================ *
         * Sticky header
         * Usage: global
         * ============================================ */
        headerSticky: function () {
            var vm = this,
                header = document.querySelector('.header-main')

            function sameValueOnChange() {
                var currentValue = this.value
                Array.from(inputSearch).forEach(function (el) {
                    el.value = currentValue
                })
            }

            /* move unique elements from header to sticky header */
            function init() {
                body.classList.add('header-is-sticky')
                nav.classList.add('animated')
                sticky.classList.add('is-sticky')
                // sticky
                //     .querySelector('.header-sticky__minicart')
                //     .appendChild(cart)
                nav.classList.remove('collapse')
                nav.classList.add('animated')
                nav.style.top = sticky.offsetHeight
                if (!body.classList.contains('menu-is-open') && !document.querySelector('.megamenu-container')) {
                    nav.style.visibility = 'hidden'
                }
            }

            /* move unique elements from sticky header to main header */
            function destroy() {
                // var headerCart = middle.querySelector('.minicart')
                sticky.classList.remove('is-sticky')
                sticky.classList.add('fadeOutUp')
                // if (headerCart.querySelector('#macopedia-ajax-basket-sidebar') == null) {
                //     headerCart.appendChild(cart);
                // }
                nav.setAttribute('style', '')
                nav.classList.remove('fadeOutUp', 'fadeInDown')
                document.documentElement.classList.remove(
                    'header-is-sticky',
                    'menu-is-open'
                )
                document
                    .querySelector('.header-sticky__btn')
                    .classList.remove('is-toggle')
                if (document.querySelector('.header-main__nav')) {
                    document.querySelector('.header-main__nav').style.visibility = '';

                    document
                        .querySelector('.header-main__nav')
                        .classList.remove('fadeOutUp', 'fadeInDown')
                }
            }

            /* set wrapper height as fixed element to omit
             page jump on scroll on small desktop and mobile */
            function changeHeightOfMenu() {
                if (
                    window.innerWidth < apSettings.bp.lg &&
                    window.innerWidth > apSettings.bp.sm
                ) {
                    headerNavWrapper.style.height = nav.offsetHeight
                } else {
                    headerNavWrapper.style.height = 'auto'
                }
            }

            if (header !== null) {
                var middle = header.querySelector('.header-main__middle'),
                    sticky = header.querySelector('.header-sticky'),
                    cart = document.getElementById(
                        'macopedia-ajax-basket-sidebar'
                    ),
                    inputSearch = document.querySelectorAll(
                        '.input-top-search'
                    ),
                    nav = document.querySelector('.header-main__nav, .megamenu-container'),
                    body = document.documentElement,
                    headerNavWrapper = document.querySelector(
                        '.header-main__wrapper'
                    ),
                    resizeTimer

                /* fill search inputs with the same values on change */
                Array.from(inputSearch).forEach(function (el) {
                    el.addEventListener('change', sameValueOnChange)
                    el.addEventListener('keyup', sameValueOnChange)
                })

                /* buttons works only on mobile */
                var headerOptionToggle = document.querySelector(
                    '.header-main .option[data-toggle]'
                )
                if (headerOptionToggle !== null) {
                    header
                        .querySelector('.option[data-toggle]')
                        .addEventListener('click.toggle', function () {
                            this.classList.toggle('is-toggle')
                        })
                }

                if (vm.bp.current === 'lg') {
                    nav.style.height = nav.offsetHeight
                }
                /* set top position to navigation */

                changeHeightOfMenu()

                //delayed resize function to limit function invokes
                window.addEventListener('resize', function () {
                    clearTimeout(resizeTimer)
                    resizeTimer = setTimeout(changeHeightOfMenu, 250)
                })

                if (sticky !== null) {
                  this.addMultipleEventListener(
                    window,
                    ['scroll', 'touchmove'],
                    () => {

                      var activeDropdown = body.classList.contains(
                          'dropdown-is-open--mobile'
                        ),
                        activeSticky = body.classList.contains(
                          'header-is-sticky'
                        ),
                        activeSuggester = body.classList.contains(
                          'suggester-is-focus'
                        ),
                        headerHeight = header.offsetHeight,
                        currentPos = (header.getBoundingClientRect().top * -1)

                      /* check if scroll position is bigger then header height */
                      var limit = headerHeight + 5

                      if (
                        currentPos > limit &&
                        !(activeDropdown || activeSuggester) &&
                        !activeSticky
                      ) {
                        init()
                      } else if (currentPos < limit && activeSticky) {
                        destroy()
                      }
                    }
                  )
                }
            }
        },

        /* ============================================ *
         * Sliders initialize
         * ============================================ */
        slidersInit: function () {
            this._sliderMain()
            this._sliderProduct()
            this._sliderProductRelated()
        },

        /* ============================================ *
         * Slider for home page
         * ============================================ */
        _sliderMain: function () {
            const blazyMainSlider = new Blazy({
                selector: '.slider-main .b-lazy',
                loadInvisible: true
            })
            const sliderSelector = document.querySelector(
                '.slider-main .slider-main__slides'
            )
            const sliderTitlesSelector = document.querySelector(
                '.slider-main .slider-main__titles'
            )
            const sliderContainerSelector = document.querySelector(
                '.slider-main'
            )

            if (sliderSelector) {
                const sliderDuration =
                    Number(sliderSelector.dataset.duration) || 6000

                var alreadyViewed = []
                sliderSelector.addEventListener(
                    'glider-slide-visible',
                    (event) => {
                        setTimeout(() => {
                            blazyMainSlider.revalidate()
                            gtmPromo(glider, event.detail.slide)
                        }, 0)
                    }
                )
                sliderSelector.addEventListener('glider-loaded', () => {
                    setTimeout(() => {
                        blazyMainSlider.revalidate()

                        Array.from(glider.slides).forEach((slide) => {
                            slide.addEventListener('click', function () {
                                GoogleAnalyticsUniversal.prototype.clickBanner(
                                    slide.dataset.id,
                                    slide.dataset.name,
                                    'slider-main',
                                    slide.dataset.position
                                )
                            })
                        })
                    }, 0)
                })
                var glider = new Glider(sliderSelector, {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    rewind: true,
                    draggable: false,
                    scrollLock: true,
                    scrollLockDelay: 100,
                    duration: 0.3,
                    arrows: {
                        prev: '.slider-main__prev',
                        next: '.slider-main__next'
                    },
                    dots: '.slider-main .slider-main__dots'
                })

                sliderContainerSelector &&
                    gliderAutoplay(
                        glider,
                        sliderContainerSelector,
                        sliderDuration
                    )

                document
                    .querySelector('.slider-main')
                    .classList.remove('slider-main--loading')

                const navButtons = sliderContainerSelector.querySelectorAll(
                    '.slider-main__titles button'
                )
                if (navButtons.length) {
                    navButtons[0].classList.add('current-slide')
                }

                if (window.innerWidth >= 768 && sliderTitlesSelector) {
                    sliderSelector.addEventListener(
                        'glider-slide-visible',
                        function (event) {
                            navButtons.forEach((button) => {
                                button.classList.remove('current-slide')
                            })
                            navButtons[event.detail.slide].classList.add(
                                'current-slide'
                            )
                        }
                    )

                    navButtons.forEach(function (el, index) {
                        el.addEventListener('click', function () {
                            glider.scrollItem(index)
                        })
                    })
                }
            }

            function isOnScreen(el) {
                var rect = el.getBoundingClientRect(),
                    vWidth =
                        window.innerWidth ||
                        document.documentElement.clientWidth,
                    vHeight =
                        window.innerHeight ||
                        document.documentElement.clientHeight,
                    efp = function (x, y) {
                        return document.elementFromPoint(x, y)
                    }

                // Return false if it's not in the viewport
                if (
                    rect.right < 0 ||
                    rect.bottom < 0 ||
                    rect.left > vWidth ||
                    rect.top > vHeight
                )
                    return false

                // Return true if any of its four corners are visible
                return (
                    el.contains(efp(rect.left, rect.top)) ||
                    el.contains(efp(rect.right, rect.top)) ||
                    el.contains(efp(rect.right, rect.bottom)) ||
                    el.contains(efp(rect.left, rect.bottom))
                )
            }

            function gtmPromo(glider, currentSlide) {
                const slide = glider.slides[currentSlide]
                if (
                    !alreadyViewed.includes(slide.dataset.name) &&
                    isOnScreen(slide)
                ) {
                    alreadyViewed.push(slide.dataset.name)
                    GoogleAnalyticsUniversal.prototype.addPromotion(
                        slide.dataset.id,
                        slide.dataset.name,
                        slide.dataset.position,
                        'slider-main'
                    )
                }
            }
        },

        /* ============================================ *
         * Slider for product image
         * ============================================ */
        _sliderProduct: function () {
            const blazyProductSlider = new Blazy({
                selector: '.slider--product .b-lazy',
                loadInvisible: true
            })

            var sliderSelector = document.querySelector('.slider--product')

            if (sliderSelector !== null) {
                var sliderNavPrev = document.querySelector(
                    '.product-gallery__btn--left'
                )
                var sliderNavNext = document.querySelector(
                    '.product-gallery__btn--right'
                )
                var glider = new Glider(sliderSelector, {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    rewind: true,
                    draggable: false,
                    scrollLock: true,
                    scrollLockDelay: 75,
                    dots: '.product-gallery .product-gallery__dots',
                    arrows: {
                        prev: sliderNavPrev,
                        next: sliderNavNext
                    }
                })

                sliderNavPrev.style.display = 'inline'
                sliderNavNext.style.display = 'inline'

                const thumbs = document.querySelectorAll(
                        '.product-thumbnails .product-thumbnails__thumb'
                    ),
                    isGalleryWithMorePhotos = document.querySelector(
                        '.product-thumbnails__thumb--more-photos'
                    ),
                    fullPageBtn = document.querySelector(
                        '.product-gallery__full-page-btn'
                    )

                if (thumbs.length) {
                    thumbs[0]?.classList.add('active-slide')

                    thumbs.forEach((thumb, index) => {
                        thumb.addEventListener('click', () => {
                            if (!(isGalleryWithMorePhotos && index === 4)) {
                                glider.scrollItem(index)
                            }
                        })
                    })

                    sliderSelector.addEventListener(
                        'glider-slide-visible',
                        (slide) => {
                            blazyProductSlider.revalidate()

                            thumbs.forEach((thumb) => {
                                thumb.classList.remove('active-slide')
                            })
                            let currentSlide = slide?.detail?.slide

                            fullPageBtn.dataset.slideId = currentSlide

                            if (currentSlide > 3) {
                                currentSlide = 3
                            }

                            thumbs[currentSlide].classList.add('active-slide')
                        }
                    )
                }
            }
        },

        /* ============================================ *
         * Sliders for related products
         * ============================================ */
        _sliderProductRelated: function () {
            var sliderSelector = document.querySelectorAll(
                '.product-slider, .slider--boxes, .bestseller-box, .content-slider'
            )
            var glider
            if (sliderSelector.length) {
                var _ = this,
                    el = sliderSelector,
                    i = 0

                handleSliderArrows(slider)
                for (i; i < el.length; i++) {
                    var slider = el[i].querySelector(
                        '.product-slider__list, .slider--row, .slider-init'
                    )

                    if (slider.classList.contains('glider')) {
                        continue
                    }

                    if (!slider.classList.contains('glider')) {
                        var dataSettings = JSON.parse(slider.dataset.glider)
                        glider = new Glider(slider, {
                            slidesToShow: dataSettings.slidesToShow || 1,
                            slidesToScroll: dataSettings.slidesToScroll || 1,
                            draggable: false,
                            rewind: true,
                            arrows: {
                                prev: el[i].querySelector(
                                    '.product-slider__btn--left, .slider__switcher--left'
                                ),
                                next: el[i].querySelector(
                                    '.product-slider__btn--right, .slider__switcher--right'
                                )
                            },
                            responsive: dataSettings.responsive
                        })
                        handleSliderArrows(glider)

                        if (dataSettings.autoPlayDelay) {
                            gliderAutoplay(glider, slider, dataSettings.autoPlayDelay)
                        }

                        window.GoogleAnalyticsUniversal.prototype.listenImpressionClick(
                            glider.ele
                        )
                    }

                    slider.addEventListener('glider-slide-visible', () =>
                        this.blazy.revalidate()
                    )
                    slider.addEventListener('glider-loaded', function () {
                        handleSliderArrows(glider)
                    })
                    slider.addEventListener(
                        'glider-slide-visible',
                        function () {
                            handleSliderArrows(glider)
                        }
                    )
                }
            }
        },

        /* ============================================ *
         * Filter navigation
         * Usage: shop category page
         * ============================================ */
        filterNavigation: function () {
            var filter = document.querySelector('.filter-nav')

            if (filter !== null) {
                var filterNavElements = filter.querySelectorAll(
                    '.filter-nav__selector'
                )
                Array.from(filterNavElements).forEach(function (el) {
                    el.addEventListener('change', function () {
                        if (this.value.length) {
                            window.location = this.value
                        }
                    })
                })

                this._filterPrice()
            }
        },

        /* ============================================ *
         * Filter navigation / price
         * Usage: shop category page
         * ============================================ */
        _filterPrice: function () {
            var filter = document.querySelector('#filter-price')
            var minInput = filter.querySelector('#price-from'),
                maxInput = filter.querySelector('#price-to'),
                btn = filter.querySelector('button')

            btn.addEventListener('click', function () {
                if (minInput.value && maxInput.value) {
                    var url = window.location.href
                    window.location =
                        url +
                        (url.split('?')[1] ? '&' : '?') +
                        'price=' +
                        parseInt(minInput.value) +
                        '-' +
                        parseInt(maxInput.value)
                }
            })
        },

        /* ============================================ *
         * Go to main content
         * Usage: global
         * ============================================ */
        goToMainContent: function () {
            var headerLevelOne = document.querySelector('h1')
            var goToContent = document.querySelector('#go-to-content a')
            if (headerLevelOne !== null && goToContent !== null) {
                goToContent.addEventListener('click', function (e) {
                    headerLevelOne.setAttribute('tabindex', 0)
                    headerLevelOne.setAttribute('id', 'heading-main')
                    headerLevelOne.focus()
                    window.location.hash = '#heading-main'
                    e.preventDefault()
                })
            }
        },

        /* ============================================ *
         * Megamenu header height
         * Usage: global
         * ============================================ */
        megaMenuOld: function () {
          if (window.JSAPP_CONFIG['MEGA_MENU_ENABLED']) {
            return;
          }
            var navMain = document.querySelector('.header-main__nav .nav-main')

            function checkIsDesktop() {
                isDesktop = window.innerWidth >= apSettings.bp.md
            }

            if (navMain !== null) {
                var navParents = navMain.querySelector('.level0.parent'),
                    navNotParents = navMain.querySelectorAll(
                        '#header-main .nav-main__primary > .level0:not(.parent)'
                    ),
                    timerIn,
                    timerOut,
                    navWidth = navMain.offsetWidth,
                    isDesktop = true

                checkIsDesktop()

                window.addEventListener('resize', function () {
                    /* on resize clearing stles which are added
                     to mobile menu when accoridion is expanded */
                    Array.from(
                        document.querySelectorAll('.nav-main__submenu')
                    ).forEach(function (el) {
                        el.setAttribute('style', '')
                    })
                    checkIsDesktop()
                })

                Array.from(navMain.querySelectorAll('li.has-col')).forEach(
                    function (el) {
                        var width = el.querySelector('.nav-main__submenu')
                                .offsetWidth,
                            offsetLeft =
                                el.getBoundingClientRect().left -
                                navMain.getBoundingClientRect().left
                        if (width + offsetLeft > navWidth) {
                            el.classList.add('has-col--right')
                        }
                    }
                )

                Array.from(navNotParents).forEach(function (el) {
                    el.addEventListener('mouseenter', function () {
                        document.documentElement.classList.remove(
                            'dropdown-is-open'
                        )
                    })
                })

                navParents.addEventListener('mouseenter', function () {
                    if (isDesktop) {
                        var el = this.querySelectorAll('.submenu')
                        Array.from(el).forEach(function (el) {
                            if (el.classList.contains('is-open')) {
                                clearTimeout(timerOut)
                            } else {
                                timerIn = setTimeout(function () {
                                    document.documentElement.classList.add(
                                        'dropdown-is-open'
                                    )
                                    el.classList.add('is-open')
                                    el.style.display = 'block'
                                }, 150)
                            }
                        })
                    }
                })

                navParents.addEventListener('mouseleave', function () {
                    if (isDesktop) {
                        var _ = this
                        var el = _.querySelectorAll('div')
                        clearTimeout(timerIn)
                        timerOut = setTimeout(function () {
                            if (!checkIsHover(_)) {
                                Array.from(el).forEach(function (el) {
                                    el.classList.remove('is-open')
                                    el.style.display = 'none'
                                })
                                const isOpenMain = navMain.querySelector(
                                    '.is-open'
                                )
                                if (!isOpenMain) {
                                    let isHover = false
                                    if (
                                        navParents.parentElement.querySelector(
                                            ':hover'
                                        ) !== null
                                    ) {
                                        isHover = true
                                        return
                                    }
                                    if (!isHover) {
                                        document.documentElement.classList.remove(
                                            'dropdown-is-open'
                                        )
                                    }
                                }
                            }
                        }, 100)
                    }
                })

                Array.from(navMain.querySelectorAll('.level0 > a')).forEach(
                    function (el) {
                        el.addEventListener('click', function (e) {
                            if (this.getAttribute('href') !== '#') {
                                navMain
                                    .querySelector('.active')
                                    ?.classList?.remove('active')
                                e.target?.classList?.add('active')
                            } else {
                                e.preventDefault()
                            }
                        })
                    }
                )

                document.documentElement.addEventListener('click', function () {
                    document
                        .querySelector('#categories-btn')
                        .classList.remove('is-open')
                    document
                        .querySelector('#categories-dropdown')
                        .classList.remove('is-open')
                })

                document
                    .querySelector('#categories-btn')
                    .addEventListener('click', function (e) {
                        this.classList.toggle('is-open')
                        document
                            .querySelector('#categories-dropdown')
                            .classList.toggle('is-open')
                        e.stopPropagation()
                    })

                document
                    .querySelector('#categories-dropdown')
                    .addEventListener('click', function (e) {
                        e.stopPropagation()
                    })
            }
        },

        /* ============================================ *
         * Function to changed location in pager on customer panel & products list
         * Usage: shop category page
         * ============================================ */
        pager: function () {
            const categoryPagerControls = Array.from(
                document.querySelectorAll('#category-limiter, #category-sort')
            )
            if (categoryPagerControls.length) {
                categoryPagerControls.forEach((select) => {
                    select.addEventListener('change', function () {
                        if (this.value.length) {
                            window.location = this.value
                        }
                    })
                })
            }
        },

        /* ============================================ *
         * AEO STATIC CALLBACK FUNCTIONS
         * Usage: shop
         * ============================================ */
        aoeStaticCallback: function () {
            var _ = this
            document.documentElement.addEventListener(
                'aoestatic_afterblockreplace',
                function () {
                    var asPlaceholders = document.querySelectorAll(
                        '.as-placeholder'
                    )
                    Array.from(asPlaceholders).forEach(function (el) {
                        if (el.dataset.placeholderDisplay) {
                            el.style.display = el.dataset.placeholderDisplay
                        }
                    })
                    _._sliderProductRelated()
                }
            )
        },

        /* ============================================ *
         * FAQ Show opened question with url
         * Usage: shop list/faq page
         * ============================================ */
        faq: function () {
            const faqSelector = document.querySelector('.faq')
            if (faqSelector !== null) {
                const faqLinksSelector = faqSelector.querySelectorAll('a')
                const hash = document.location.hash.toString()
                const pathname = window.location.pathname
                Array.from(faqLinksSelector).forEach(function (el) {
                    const currentId = el.getAttribute('id')
                    el.setAttribute('id', 'faq-' + currentId)
                    el.addEventListener('click', function () {
                        if (history.pushState) {
                            history.pushState(
                                null,
                                null,
                                pathname + '#' + this.getAttribute('id')
                            )
                        } else {
                            location.hash = this.getAttribute('id')
                        }
                    })
                })
                if (pathname.indexOf('pomoc') !== -1 && hash) {
                    const hashElement = document.querySelector(hash)
                    hashElement.click()
                    setTimeout(function () {
                        document.documentElement.scrollTo(
                            0,
                            hashElement.getBoundingClientRect().top - 100
                        )
                    }, 500)
                }
            }

            const faq = document.querySelector('.content-faq')
            if (faq) {
                const questions = [...document.querySelectorAll(".content-faq__header")]
                questions.forEach(el => {
                    el.addEventListener("click", e => {
                        el.classList.toggle('is-active')
                    })
                })
            }
        },

        inputTrimmer: function () {
            import('../utils/inputTrimmer.js').then(({ inputTrimmer }) => {
                inputTrimmer()
            })
        },

        multilineSelect: function () {
            var selects = Array.from(document.querySelectorAll('select'))
            iosMultilineSelect(selects)
        },

        initIntlTelInput: function () {
            const selector = 'input[name="telephone"]:not([type="hidden"])'
            if (document.querySelector(selector)) {
                import('intl-tel-input').then((module) => {
                    window.intlTelInput = module.default
                    const input = document.querySelector(selector)
                    const iti = window.intlTelInput(input, {
                        preferredCountries: ['pl'],
                        hiddenInput: 'telephone',
                        onlyCountries: window.JSAPP_CONFIG.SMS_COUNTRIES_ALLOWED
                    })

                    const updateHiddenInput = function (input, iti) {
                        if (
                            input &&
                            input.nextElementSibling &&
                            iti.isValidNumber()
                        ) {
                            input.nextElementSibling.value = iti.getNumber()
                            input.classList.add('valid-phone-number')
                        } else {
                            input.classList.remove('valid-phone-number')
                        }
                    }

                    'change countrychange'.split(' ').forEach((eventName) => {
                        input.addEventListener(eventName, function () {
                            updateHiddenInput(input, iti)
                        })
                    })

                    window.intlTelInputGlobals
                        .loadUtils('/js/intl-tel-input/utils.js')
                        .then(function () {
                            updateHiddenInput(input, iti)
                        })
                })
            }
        },

        hellobar: function () {
            const hellobar = document.querySelector('.js-hellobar')
            if (hellobar) {
                document.documentElement.addEventListener(
                    'aoestatic_afterblockreplace',
                    () => {
                        const hellobarData = document.querySelector('.hellobar')
                        if (hellobarData) {
                            let expireTime = 1
                            const close = document.querySelector(
                                    '.js-hellobar-close'
                                ),
                                expireTimeData = document.querySelector(
                                    '.hellobar__wrapper'
                                ).dataset.reappear,
                                hellobarLink = document.querySelector(
                                    '.hellobar__btn'
                                ),
                                content = document.querySelector(
                                    '.js-hellobar-content'
                                ),
                                id = content.dataset.id,
                                title = content.dataset.title

                            if (expireTimeData.length > 0) {
                                expireTime = Number(expireTimeData)
                            }

                            close.addEventListener('click', () => {
                                hellobar.classList.add('is-hidden')
                                Cookies.set(
                                    'hellobar_cookie_' + id,
                                    'hellobar',
                                    { expires: expireTime }
                                )
                            })

                            if (hellobarLink) {
                                hellobarLink.addEventListener('click', () => {
                                    dataLayer.push({ event: 'hellobar_click' })
                                    Cookies.set(
                                        'hellobar_cookie_' + id,
                                        'hellobar',
                                        { expires: expireTime }
                                    )
                                })
                            }

                            dataLayer.push({
                                hellobar: {
                                    id: 'hellobar_' + id,
                                    name: title,
                                    link: hellobarLink ? hellobarLink.href : ''
                                }
                            })
                        }
                    }
                )
            }
        },

        fixedCartBox: function () {
            const cartBox = document.querySelector('.sticky-product-box')
            const closeIcon = document.querySelector('.js-close-box')

            if (cartBox) {
                closeIcon.addEventListener('click', () => {
                    cartBox.classList.add('is-hidden')
                })

                const productToc = document.querySelector('.product-usp')
                if (productToc) {
                    this.addMultipleEventListener(
                        document,
                        ['scroll', 'touchmove'],
                        () => {
                            const cartBox = document.querySelector(
                                '.sticky-product-box'
                            )
                            const productToc = document.querySelector(
                                '.product-usp'
                            )
                            const tocPosition = productToc.getBoundingClientRect()
                                .top
                            if (window.scrollY >= tocPosition) {
                                cartBox.classList.add('is-visible')
                            } else {
                                cartBox.classList.remove('is-visible')
                            }
                        }
                    )
                }
            }
        },

        scrollToAnchor: function () {
            const tableOfContents = document.querySelector('.widget-toc')
            const descriptionToc = document.querySelector(
                '.product-overview__toc'
            )

            if (tableOfContents) {
                const links = [...document.querySelectorAll('.widget-toc a')]
                links.forEach((el) => {
                    el.addEventListener('click', (e) => {
                        e.preventDefault()
                        const href = el.getAttribute('href').slice(1)
                        const target = document.getElementById(href)
                        target.scrollIntoView({
                            behavior: 'smooth'
                        })
                    })
                })

                const arrow = document.querySelector('.js-text-toggle')
                if (arrow) {
                    arrow.addEventListener('click', function () {
                        tableOfContents.classList.toggle('is-expanded')
                    })
                }
            }

            if (descriptionToc) {
                const descriptionLinks = [
                    ...document.querySelectorAll('.product-overview__toc a')
                ]
                const listLength = descriptionLinks.length
                if (listLength < 6) {
                    tableOfContents.classList.add('is-short')
                }
            }
        },

      rolloutFooterMegaLinks: function () {
        const footerMegaLinksWrappers = document.querySelectorAll('.footer__mega-links-wrapper')

        if (footerMegaLinksWrappers && footerMegaLinksWrappers.length) {

          footerMegaLinksWrappers.forEach((footerMegaLinksWrapper) => {

            const rolloutButton = footerMegaLinksWrapper.querySelector('.footer__mega-links-rollout')
            const rolloutButtonText = footerMegaLinksWrapper.querySelector('.footer__mega-links-rollout--text')
            const tables = footerMegaLinksWrapper.querySelectorAll('.footer__mega-links-content > div')
            const arrowIcon = footerMegaLinksWrapper.querySelector('.footer__mega-links-rollout .icon-arrow-indicator')
            const amountOfTablesPerRow = 6

            const rolloutNextRow = () => {
              const showMoreRowsText = 'Zobacz więcej'
              const isAllRowsVisibilityTriggered = rolloutButtonText.innerText.trim() === showMoreRowsText

              tables.forEach((table, index) => {
                if (index < amountOfTablesPerRow) {
                  return;
                }
                if (isAllRowsVisibilityTriggered) {
                  table.classList.add("displayed");
                } else {
                  table.classList.remove("displayed");
                }

                if (tables.length === index + 1) {
                  rolloutButtonText.innerText = isAllRowsVisibilityTriggered ? 'Zobacz mniej' : showMoreRowsText
                  if (isAllRowsVisibilityTriggered) {
                    arrowIcon.style.transform = 'rotate(180deg)'
                    return;
                  }
                  arrowIcon.style.transform = 'none'
                }
              });
            }

            const rolloutFirstRow = () => {
              if (tables.length === amountOfTablesPerRow) {
                rolloutButton.classList.add("hidden")
              }
              tables.forEach((table, index) => {
                if (index + 1 > amountOfTablesPerRow) {
                  return;
                }
                if (!table.classList.contains("displayed")) {
                  table.classList.add("displayed")
                }
              })
            }

            rolloutFirstRow();
            rolloutButton?.addEventListener("click", rolloutNextRow)
          });
        }
      },

        showFooterArticle: function() {
            const readMoreButton = document.querySelector('.footer__article-read-more')

            readMoreButton?.addEventListener('click', (e) => {
                const article = document.querySelector('.footer__article')
                const selector = readMoreButton.querySelector('.text')

                if (article.classList.contains('is-expanded')) {
                    scrollTo(article)

                    if (selector) {
                      selector.innerHTML = readMoreButton.dataset.show
                    }
                } else {

                    if (selector) {
                      readMoreButton.querySelector('.text').innerHTML = readMoreButton.dataset.hide
                    }
                }
                article.classList.toggle('is-expanded')
            })
        },

        tabs: function () {
            const tabs = document.querySelectorAll('.tabs-active')

            if (tabs.length > 0) {
                tabs.forEach((el) => {
                  const tabHeaders = [
                      ...el.querySelectorAll('.js-tabs-header')
                  ]
                  const tabContent = [
                      ...el.querySelectorAll('.js-tabs-content')
                  ]

                  tabHeaders.forEach(function (tab) {
                      tab.addEventListener('click', openTab)
                  })

                  function openTab(tab) {
                      const el = tab.currentTarget
                      const name = el.dataset.tab

                      tabContent.forEach(function (el) {
                          el.classList.remove('is-active')
                      })

                      tabHeaders.forEach(function (el) {
                          el.classList.remove('is-active')
                      })

                      document.getElementById(name).classList.add('is-active')
                      el.classList.add('is-active')
                  }

                  const menuArrow = el.querySelector('.js-show-headers'),
                    menu = el.querySelector('.c-product-categories__menu'),
                    headers = [...el.querySelectorAll(".c-product-categories__header")]

                  if (menu && menuArrow) {
                    menuArrow.addEventListener('click', function () {
                      menu.classList.toggle('show-all')
                    })
                  }

                  if (menu && headers.length < 4) {
                    menu.classList.add('hide-arrow')
                  }
                })
            }
        },

        cartStickySummaryTop: function () {
            const cartSummaryTop = document.querySelector('.cart-summary-top--is-sticky'),
                  cartTable = document.getElementById('product-forms'),
                  cartStickyStart = document.getElementById('js-cart-sticky-start'),
                  cartSummaryBottom = document.querySelector('.cart-summary')

            if (null === cartSummaryTop || null === cartTable || cartStickyStart === null) {
                return
            }

            this.addMultipleEventListener(
                document,
                ['scroll', 'touchmove'],
                () => {
                    if (cartStickyStart.getBoundingClientRect().top <= 0) {
                        cartSummaryTop.classList.add('cart-summary-top--sticky')
                        cartTable.style.marginTop =
                            cartSummaryTop.offsetHeight + 'px'
                    } else {
                        cartTable.style.marginTop = null
                        cartSummaryTop.classList.remove(
                            'cart-summary-top--sticky'
                        )
                    }
                }
            )

            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting && cartSummaryTop.classList.contains('cart-summary-top--sticky')) {
                            cartSummaryTop.classList.add('cart-summary-top--mobile-hidden')
                        } else {
                            cartSummaryTop.classList.remove('cart-summary-top--mobile-hidden')
                        }
                    })
                },
                {
                    rootMargin: '0px',
                    threshold: 0.1
                }
            )
            observer.observe(cartSummaryBottom)
        },

        addMultipleEventListener: function (element, events, handler) {
            events.forEach((e) => element.addEventListener(e, handler))
        }
    }

    ready(function () {
        var site = new Site()
        site.start()
    })
})()
